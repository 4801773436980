<template>
  <li class="flex gap-2 items-center">
    <div class="border flex-grow rounded-xl border-gray-150" :class="{ 'cursor-pointer': !isEditing }" @click="$emit('reservation', sololink)">
      <div class="flex space-x-2 items-center py-1 px-2">
<!--        <img v-if="sololink.slim_restaurant.profile_picture" class="rounded-full w-14 h-14 flex-shrink-0" :src="sololink.slim_restaurant.profile_picture" alt="foodie" />-->
<!--        <img v-else class="rounded-full w-14 h-14 flex-shrink-0" src="../../../assets/logo.png" alt="foodie" />-->
        <a-initials :name="sololink?.restaurant?.name" :src="sololink?.restaurant?.profile_picture" :desing-class="'h-14 w-14'"></a-initials>
        <span class="text-black font-medium text-lg">{{ sololink.restaurant.name }}</span>
      </div>
    </div>
    <button class="text-red-500 p-1" :disabled="loading" @click="remove" v-if="isEditing && belongsToFoodie(sololink.foodie_id)">
      <a-loader class="h-5 w-5" v-if="loading" />
      <i class="icon-close" v-else />
    </button>
  </li>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useRepository, useSession } from '../../../hooks'

export default defineComponent({
  props: {
    sololink: { required: true, type: Object },
    isEditing: { required: false, type: Boolean }
  },
  emits: ['remove', 'reservation'],
  setup (props, { emit }) {
    const loading = ref(false)
    const request = useRepository(({ sololinks }) => sololinks.delete)
    const { isAuthenticated, belongsToFoodie } = useSession()

    const remove = () => {
      loading.value = !loading.value

      request(props.sololink.id)
        .then(() => {
          emit('remove')
        })
        .finally(() => (loading.value = !loading.value))
    }

    return {
      remove,
      loading,
      isAuthenticated,
      belongsToFoodie
    }
  }
})
</script>
